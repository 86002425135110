import React from 'react';
import './logo.css';

class Logo extends React.Component {
    render() {
      return (
        <div>
          <h1 className="logo">Black & White</h1>
        </div>
      );
    }
  }

export default Logo;